import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { Heading } from '@pancakeswap-libs/uikit'
import FlexLayout from 'components/layout/Flex'
import Page from 'components/layout/Page'
import useI18n from 'hooks/useI18n'
import VestCard from './VestCard/VestCard'

export interface FarmsProps {
  tokenMode?: boolean
}

const Vesting: React.FC<FarmsProps> = ({ tokenMode }) => {
  const { path } = useRouteMatch()
  const TranslateString = useI18n()
  
  return (
    <Page>
      <div style={{ marginTop: "252px" }}>
        <Heading as="h1" size="lg" color="secondary" mb="15px" style={{ textAlign: 'center' }}>
          {tokenMode ? TranslateString(999, 'AA') : TranslateString(999, '')}
        </Heading>

        <Heading as="h2" color="#bdbdbd" mb="12px" style={{ textAlign: 'center' }}>
          {TranslateString(0, '')}
        </Heading> 

        <Heading as="h2" color="#bdbdbd" mb="50px" style={{ textAlign: 'center' }}>
          {TranslateString(0, '')}
        </Heading> 
        
        <div>
          <FlexLayout>
            <Route exact path={`${path}`}>
              <VestCard 
                farmImage="example.png"
                tokenSymbol="PS"
              />
            </Route>
            <Route exact path={`${path}/history`}>
              <VestCard 
                farmImage="example.png"
                tokenSymbol="PS"
                removed
              />
            </Route>
          </FlexLayout>
        </div>
      </div>
    </Page>
  )
}

export default Vesting
