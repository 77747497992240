import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [



{
  pid: 0, 
  risk: 1,
  lpSymbol: 'WETH - WIT',  
  lpAddresses: {
    1: '0xDd13206446E27C53f9D99bC13fC137048a33a8bF', // weth WIT LP on UNISWAP v2
    81457: '', 
    97: '',
    56: '',    
  },
  tokenSymbol: 'WIT',
  tokenAddresses: {
    1: '0x0812EEBCd567a26b53B34900dC412Fe7Fc8e557c',    
    81457: '',
    97: '',
    56: '',
  },
  quoteTokenSymbol: QuoteToken.BNB,
  quoteTokenAdresses: contracts.wbnb,
},

{
  pid: 1,
  risk: 1,
  isTokenOnly: true,
  lpSymbol: 'WRC',  
  lpAddresses: {
    1: '0x0a91C92D7E8dF74BD65072a324C8Ff17404CC77B',  // 
    81457: '',  
    97: '',
    56: '',    
  },
  tokenSymbol: 'WRC',
  tokenAddresses: {
    1: '0x0a91C92D7E8dF74BD65072a324C8Ff17404CC77B',
    81457: '',
    97: '',
    56: '',
  },
  quoteTokenSymbol: QuoteToken.BUSD,
  quoteTokenAdresses: contracts.busd,
},

{
pid: 2,  
risk: 1,
lpSymbol: 'WRC - PAXG',  
lpAddresses: {
  1: '0xD00833c6E989E0baAA8AfadEB85F4b134cD4d5ed', // weth WIT LP on UNISWAP v2
  81457: '', 
  97: '',
  56: '',    
},
tokenSymbol: 'WRC',
tokenAddresses: {
  1: '0x0a91C92D7E8dF74BD65072a324C8Ff17404CC77B',    
  81457: '',
  97: '',
  56: '',
},
quoteTokenSymbol: QuoteToken.PAXG,
quoteTokenAdresses: contracts.paxg,
}




]

export default farms