import React, { useEffect, Suspense, lazy } from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { ResetCSS } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import { useFetchPublicData } from 'state/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import PageLoader from './components/PageLoader'
import './custom-styles.css'
import Vesting from "./Vesting"

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page'
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const Pools = lazy(() => import('./views/Pools'))
const NotFound = lazy(() => import('./views/NotFound'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,

                                        
})
const Background = styled.div`
  background-image: url('images/egg/3b.png');
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;

  @media (max-width: 768px) {
    background-size: cover; 
    background-position: center; 
  }
`;

const App: React.FC = () => {
  const { account, connect } = useWallet()
  useEffect(() => {
    if (!account && window.localStorage.getItem('accountStatus')) {
      connect('injected')
    }
  }, [account, connect])

  useFetchPublicData()

  return (
    <Router>
      <ResetCSS />
      <GlobalStyle />
      <Background />
      <Menu>
        <Suspense fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/pdtokenvesting">
              <Vesting />
            </Route>
            <Route path="/pools">
              <Farms tokenMode />
            </Route>
            <Route path="/staking">
              <Pools />
            </Route>
            <Route component={NotFound} />

          </Switch>
        </Suspense>
      </Menu>
    </Router>
  )
}

export default React.memo(App)
