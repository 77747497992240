import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { provider } from 'web3-core'
import cakeABI from 'config/abi/cake.json'
import vestingAbi from 'config/abi/VestAbi.json'
import masterchefABI from 'config/abi/GM-masterchef.json'
import { getContract } from 'utils/web3'
import { getTokenBalance } from 'utils/erc20'
import { getCakeAddress, getMasterChefAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import useWeb3 from './useWeb3'
import useRefresh from './useRefresh'
import useLastUpdated from './useLastUpdated'

const useTokenBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { account, ethereum }: { account: string; ethereum: provider } = useWallet()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getTokenBalance(ethereum, tokenAddress, account)
      setBalance(new BigNumber(res))
    }

    if (account && ethereum) {
      fetchBalance()
    }
  }, [account, ethereum, tokenAddress, fastRefresh])

  return balance
}

export const useTotalTokenSupply = (tokenAddress: string) => {
  const { fastRefresh } = useRefresh()
  const [totalSupply, setTotalSupply] = useState <BigNumber>()

  useEffect(() => {
    async function fetchTotalSupply() {
      const cakeContract = getContract(cakeABI, tokenAddress)
      const supply = await cakeContract.methods.totalSupply().call()
      setTotalSupply(new BigNumber(supply))
    }

    fetchTotalSupply()
  }, [tokenAddress, fastRefresh])

  return totalSupply
}


export const useTokenContractBalance = (tokenAddress: string, contractAddress: string) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const cakeContract = getContract(cakeABI, tokenAddress)
      const bal = await cakeContract.methods.balanceOf(contractAddress).call()
      setBalance(new BigNumber(bal))
    }

    fetchBalance()
  }, [tokenAddress, contractAddress, fastRefresh])

  return balance
}

export const useTotalSupply = () => {
  const { fastRefresh } = useRefresh()
  const [totalSupply, setTotalSupply] = useState<BigNumber>()

  useEffect(() => {
    async function fetchTotalSupply() {
      const cakeContract = getContract(cakeABI, getCakeAddress())
      const supply = await cakeContract.methods.totalSupply().call()
      setTotalSupply(new BigNumber(supply))
    }

    fetchTotalSupply()
  }, [fastRefresh])

  return totalSupply
}

export const useLpBalance = (tokenAddress: string, LpAddress: string) => {
  const [balance, setBalance] = useState<BigNumber>()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const contract = getContract(cakeABI, tokenAddress)
      const res = await contract.methods.balanceOf(LpAddress).call()
      setBalance(new BigNumber(res.toString()))
    }

    fetchBalance()
  }, [tokenAddress, LpAddress, fastRefresh])

  return balance
}

export const useTimeToWithdraw =  (account: string, pid: number) => {
  const [balance, setBalance] = useState <BigNumber>()
  const { fastRefresh } = useRefresh()
  

  useEffect(() => {
    const fetchBalance = async () => {
      const contract = getContract(masterchefABI, getMasterChefAddress())
      const res = await contract.methods.timeToWithdraw(account, pid).call()
      setBalance(new BigNumber(res.toString()))
    }

    fetchBalance()
  }, [pid, account, fastRefresh])

  return balance
}



/* 
export const useReadUserAmount = (contractAddress: string, pid: number) => {
  const [amount, setAmount] = useState<BigNumber>();
  const [claimed, setClaimed] = useState<BigNumber>();

  const { account, ethereum }: { account: string; ethereum: provider } = useWallet()
  
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchBalance = async () => {
      const contract = getContract(vestingAbi, contractAddress);
      const res = await contract.methods.userInfo(pid, account).call();
      const value1 = res.amount;  // or res.propertyName1
      const value2 = res.claimed;  // or res.propertyName2
      setAmount(new BigNumber(value1));
      setClaimed(new BigNumber(value2));
    }

    fetchBalance();
  }, [contractAddress, pid, account, fastRefresh]);

  return {amount, claimed};
}

export const useReadPoolInfo = (contractAddress: string) => {
  const [earnToken, setearnToken] = useState<BigNumber>();
  const [earningStart, setearningStart] = useState<BigNumber>();
  const [dailyEarnPercent, setdailyEarnPercent] = useState<BigNumber>();
  const [tokenSupply, settokenSupply] = useState<BigNumber>();
 

  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchBalance = async () => {
      const contract = getContract(vestingAbi, contractAddress);
      const res = await contract.methods.poolInfo(0).call();
      const value1 = res.earnToken;  // or res.propertyName1
      const value2 = res.earningStart;  // or res.propertyName2
      const value3 = res.dailyEarnPercent;  // or res.propertyName1
      const value4 = res.tokenSupply;  // or res.propertyName2
      setearnToken(new BigNumber(value1));
      setearningStart(new BigNumber(value2));
      setdailyEarnPercent(new BigNumber(value3));
      settokenSupply(new BigNumber(value4));
    }

    fetchBalance();
  }, [contractAddress, fastRefresh]);

  return {earnToken, earningStart, dailyEarnPercent, tokenSupply,  };
}

export const useReadPendingAndEarnedToken = (contractAddress: string) => {
  const [pending, setpending] = useState<BigNumber>();
  const [totalEarned, settotalEarned] = useState<BigNumber>();

  const { account}: { account: string } = useWallet()
 

  const { fastRefresh } = useRefresh();

  useEffect(() => {
    const fetchBalance = async () => {
      const contract = getContract(vestingAbi, contractAddress);
      const res = await contract.methods.pendingAndEarnedToken(0, account).call();
      const value1 = res.pending;  // or res.propertyName1
      const value2 = res.totalEarned;  // or res.propertyName2
      setpending(new BigNumber(value1));
      settotalEarned(new BigNumber(value2));
    }

    fetchBalance();
  }, [contractAddress, account, fastRefresh]);

  return {pending, totalEarned };
}
 */

export const useGetBnbBalance = () => {
  const [balance, setBalance] = useState(BIG_ZERO)
  const { account } = useWeb3React()
  const { lastUpdated, setLastUpdated } = useLastUpdated()
  const web3 = useWeb3()

  useEffect(() => {
    const fetchBalance = async () => {
      const walletBalance = await web3.eth.getBalance(account)
      setBalance(new BigNumber(walletBalance))
    }

    if (account) {
      fetchBalance()
    }
  }, [account, web3, lastUpdated, setBalance])

  return { balance, refresh: setLastUpdated }
}



export const useBurnedBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const cakeContract = getContract(cakeABI, tokenAddress)
      const bal = await cakeContract.methods.balanceOf('0x000000000000000000000000000000000000dEaD').call()
      setBalance(new BigNumber(bal))
    }

    fetchBalance()
  }, [tokenAddress, fastRefresh])

  return balance
}

export default useTokenBalance
