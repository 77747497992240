import BigNumber from 'bignumber.js'
import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'
import {useLpBalance} from 'hooks/useTokenBalance'
import { fetchFarmsPublicDataAsync, fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync } from './actions'
import { State, Farm, Pool } from './types'
import { QuoteToken } from '../config/constants/types'

const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  const price = usePriceWRC()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync(price))
  }, [dispatch, slowRefresh, price])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}

export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (pid) => {
  const farm = useFarmFromPid(pid)
  let harvestEnabled = -1;
 
  if ((farm.userData !== null ) && ( farm.userData !== undefined))
  {
     if ('harvestStatus' in farm.userData )
     {
       harvestEnabled = farm.userData.harvestStatus ? 1 : 0;
     }
  }

  
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : new BigNumber(0),
    harvestTimeStamp: farm.userData ? new BigNumber(farm.userData.harvestTimeStamp) : new BigNumber(0),
    canHarvest :  harvestEnabled 
  }
}


// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices

 // for ETH USD PRICE!
 export const usePriceBnbBusd = (): BigNumber => {
  const ethINLP = new BigNumber(useLpBalance("0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", "0x0d4a11d5EEaaC28EC3F61d100daF4d40471f1852"));
  const usdINLP = new BigNumber(useLpBalance("0xdAC17F958D2ee523a2206206994597C13D831ec7", "0x0d4a11d5EEaaC28EC3F61d100daF4d40471f1852"));

  return new BigNumber(usdINLP.dividedBy(ethINLP).multipliedBy(1000000000000));
};

 // for PAXG USD PRICE!
 export const usePricePAXG = (): BigNumber => {
  const ethINLP = new BigNumber(useLpBalance("0x45804880De22913dAFE09f4980848ECE6EcbAf78", "0x9C4Fe5FFD9A9fC5678cFBd93Aa2D4FD684b67C4C"));
  const usdINLP = new BigNumber(useLpBalance("0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", "0x9C4Fe5FFD9A9fC5678cFBd93Aa2D4FD684b67C4C"));

  return new BigNumber(usdINLP.dividedBy(ethINLP).multipliedBy(usePriceBnbBusd()));
};

// For WRC Price!
export const usePriceWRC = (): BigNumber => {
  const ethINLP = new BigNumber(useLpBalance("0x45804880De22913dAFE09f4980848ECE6EcbAf78", "0xD00833c6E989E0baAA8AfadEB85F4b134cD4d5ed"));
  const usdINLP = new BigNumber(useLpBalance("0x0a91C92D7E8dF74BD65072a324C8Ff17404CC77B", "0xD00833c6E989E0baAA8AfadEB85F4b134cD4d5ed"));

  return new BigNumber(ethINLP.dividedBy(usdINLP).multipliedBy(usePricePAXG()));
};

/* const ETH_PRICE = () => {
  const ethPrice = useEthPrice();

  return ethPrice;
}; 


console.log("ETH_PRICE: ", useEthPrice()); 


// WHEN POOL ETH USD exist use THIS with correct PID !
export const usePriceBnbBusd = (): BigNumber => {
  const pid = 2; // BUSD-BNB LP ( für preis berechnung des netzwerk tokens ETH/BNB usw)
  const farm = useFarmFromPid(pid);
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO;
}

*/





export const usePriceCakeBusd = (): BigNumber => {
  const pid = 0; // FARM_TOKEN - USD LP ( für preis berechnung des farm tokens)
  const farm = useFarmFromPid(pid);
  const bnbPrice = usePriceBnbBusd(); // when farm ETH USD exist use: usePriceBnbBusd()

  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote).multipliedBy(bnbPrice) : ZERO;
}


// 


export const useTotalValue = (): BigNumber => {
  const farms = useFarms();
  const bnbPrice = usePriceBnbBusd();
  const cakePrice = usePriceCakeBusd();
  const paxgPrice = usePricePAXG();
  let value = new BigNumber(0);
  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      let val;
      if (farm.quoteTokenSymbol === QuoteToken.BNB) {
        val = (bnbPrice.times(farm.lpTotalInQuoteToken));
      }else if (farm.quoteTokenSymbol === QuoteToken.CAKE) {
        val = (cakePrice.times(farm.lpTotalInQuoteToken));
      }else if (farm.quoteTokenSymbol === QuoteToken.PAXG) {
        val = (paxgPrice.times(farm.lpTotalInQuoteToken).dividedBy(2));
      }else{
        val = (farm.lpTotalInQuoteToken);
      }
      value = value.plus(val);
    }
  }
  return value;
}