import React, { useState, useEffect, useCallback } from 'react'
import BigNumber from 'bignumber.js'
import styled, { keyframes } from 'styled-components'
import { Flex, Card, CardBody, Button, Text, useWalletModal } from '@pancakeswap-libs/uikit'
import { provider as ProviderType } from 'web3-core'
import { useWallet } from '@binance-chain/bsc-use-wallet'
// import {useReadUserAmount, useReadPoolInfo, useReadPendingAndEarnedToken} from 'hooks/useTokenBalance'
import useI18n from 'hooks/useI18n'
import { useClaim } from 'hooks/useHarvest'
import { getBalanceNumber } from 'utils/formatBalance'
import Web3 from 'web3'
import rawABI from './VestAbi.json'
import GradientUnlockButton from './GradientUnlockButton'
import GradientButton from './GradientButton'

const CONTRACT_ADDRESS = '0xf5F5FA793884f787eb2E27cA3a91e9E51757Be82'

const RainbowLight = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`



const FCard = styled.div`
  align-self: baseline;
  background: ${(props) => props.theme.card.background};
  border-radius: 32px;
  box-shadow: 0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 24px;
  position: relative;
  text-align: center;
  width: 320px;
  height: 400px;
`

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.borderColor};
  height: 1px;
  margin: 28px auto;
  width: 100%;
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
`

const LeftAlignedText = styled(Text)`
  text-align: left;
  width: 100%;
`

const Actions = styled.div`
  margin-top: 24px;
`

interface VestCardProps {
  farmImage: string
  tokenSymbol: string
  removed?: boolean
}

const VestCard: React.FC<VestCardProps> = ({ farmImage, tokenSymbol, removed }) => {
  const TranslateString = useI18n()
  
  const [pendingTx, setPendingTx] = useState(false)

  const { account, connect, reset } = useWallet()
  const { onPresentConnectModal } = useWalletModal(connect, reset)

/*   const {amount, claimed} = useReadUserAmount(CONTRACT_ADDRESS, 0 )
  // DEIN MSTER den earningStart unbedingt dann als datum mit new york time angeben oder so.. 
  const {earnToken, earningStart, dailyEarnPercent, tokenSupply } = useReadPoolInfo(CONTRACT_ADDRESS);
  const { pending, totalEarned} = useReadPendingAndEarnedToken(CONTRACT_ADDRESS);

// DEIN MSTER eran token ist halt die addresse des token als String,
// DEIN MSTER das macht später mal sinn wenn es andere token zum ernen gibt!
  const amount18 = getBalanceNumber(amount);
 
  const claimed18 = getBalanceNumber(claimed);

  const tokenSupply18 = getBalanceNumber(tokenSupply);

  let pending18 = 0;

// DEIN MSTER peding soll MAXIMAL anzeigen amount-calimed

  if (pending > amount ) {

   pending18 = getBalanceNumber(amount) - getBalanceNumber(claimed) ;

  }  else pending18 = getBalanceNumber(pending);

const { onReward } = useClaim(0);
 */

  return (
    <FCard>
      {tokenSymbol === 'PS' }

      <Text bold fontSize="24px" mb="24px">
        {tokenSymbol}
      </Text>

      

      <Text bold fontSize="18px" mb="12px">
      ---  Vesting Pool Info: ---
      </Text>
{/*       
      <Flex justifyContent="space-between" alignItems="center">
        <LeftAlignedText>{TranslateString(999, 'Earning Starts: ')}:</LeftAlignedText>
        <Text bold> {earningStart ? earningStart.toFixed() : 'Loading...'}</Text>    
      </Flex>

      <Flex justifyContent="space-between" alignItems="center">
        <LeftAlignedText>{TranslateString(999, 'To Earn per Day: ')}:</LeftAlignedText>
        <Text bold> {dailyEarnPercent ? `${dailyEarnPercent.toString()}%` : 'Loading...'}</Text> 
        
      </Flex>

      <Flex justifyContent="space-between" alignItems="center">
        <LeftAlignedText>{TranslateString(999, 'Total Token to Earn: ')}:</LeftAlignedText>
        <Text bold> {tokenSupply18 ? tokenSupply18.toFixed() : 'Loading...'}</Text> 
        
      </Flex>

       */}

      <Text bold fontSize="18px" mb="12px">
      ---  User Info: ---
      </Text>

 
{/*       <Flex justifyContent="space-between" alignItems="center">
        <LeftAlignedText>{TranslateString(999, 'Total Amount')}:</LeftAlignedText>
        <Text bold> {amount18 ? amount18.toFixed(3) : 'Loading...'}</Text>    
      </Flex>

     <Flex justifyContent="space-between" alignItems="center">   
        <LeftAlignedText>{TranslateString(999, 'Total Claimed')}:</LeftAlignedText>
        <Text bold>{claimed18 ? claimed18.toFixed(3) : '0.000'}</Text>
      </Flex>

      <Flex justifyContent="space-between" alignItems="center">   
        <LeftAlignedText>{TranslateString(999, 'Your Pending Token:')}:</LeftAlignedText>
        <Text bold>{pending18 ? pending18.toFixed(5) : '0.000'}</Text>
      </Flex>
      

      <Actions>
          {account ? (
            <GradientButton
              id="harvest-all"
              disabled={pending18  === 0 }
              onClick={async () => {
                setPendingTx(true)
                await onReward()
                setPendingTx(false)
              }}
              fullWidth
            >
              {pendingTx  ? "Collecting earnings ..."  : "Claim Pending ... "}
            </GradientButton>
          ) : (
            <GradientButton fullWidth onClick={onPresentConnectModal}>
              Unlock Wallet
            </GradientButton>
          )}
        </Actions>
 */}
        <Divider />
      
 


    </FCard>
  )
}

export default VestCard
