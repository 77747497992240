export default {
  // der farming/reward Token
  cake: { 
    1: '0x0812EEBCd567a26b53B34900dC412Fe7Fc8e557c', //  WIT on ETH
    81457: '0xFf08733849cf99408C21b0b968d7e3a724c9ec48',
    56: '0x3c3B33bA5d5e6Bcf4fe71000Bf0D282709f019d9', // adamant farm token
    97: '',
  },

  masterChef: {  
    1: '0x33Eed6D66C05aA0dcae958Cf8C429762C804916B', // WIT MASTER on ETH
    81457: '0x642E775EA1223B337B8D19dBcc99ac7b6342C953',
    56: '0xE84848B8db8dB313041872354A288dF66A54e197', // adamant masterchef
    97: '',
  },

  vestContract: {     
    1: '',  // NONE
    81457: '0xf5f5fa793884f787eb2e27ca3a91e9e51757be82',
    56: '',
    97: '',
  },

  mulltiCall: {  
    1: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',  // Multicall on ETH (found onchain!)
    81457: '0xf48e6c751bfcC111eDb33804E9aF013aC4Cbf8FB',
    56: '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb', 
    97: '',
  },


  wbnb: { 
    1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',   // weth on ETH
    81457: '0x4300000000000000000000000000000000000004',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },

  busd: { 
    1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',     // USDT on ETH 
    81457: '0x4300000000000000000000000000000000000003',
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },    

  btc: { 
    1: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    81457: '0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692',
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },    
  

  paxg: { 
    1: '0x45804880De22913dAFE09f4980848ECE6EcbAf78',
    81457: '0xF7bc58b8D8f97ADC129cfC4c9f45Ce3C0E1D2692',
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },  




}